import request from "./request.js";
// var token = localStorage.getItem("token")
// 获取配置
export function getSite(params) {
  return request.get("/api/index/getSite", params);
}

// 分类列表
export function category(params) {
  return request.get("/api/index/category", params);
}
// 学校列表
export function schoolList(params) {
  return request.get("/api/index/schoolList", params);
}

//学校详情
export function school(params) {
  return request.get("/api/index/school", params);
}

//语言列表
export function languageList(params) {
  return request.get("/api/index/languageList", params);
}

export function goodsList(params) {
  return request.posta("/api/index/goodsList", params);
}

export function restGoodsList(params) {
  return request.get("/api/index/restGoodsList", params);
}

export function couponList(params) {
  return request.posta("/api/index/couponList", params);
}

export function restList(params) {
  return request.get("/api/index/restList", params);
}

export function restDetails(params) {
  return request.get("/api/index/restDetails", params);
}

//google登录
export function googleLogin(params) {
  return request.posta("/api/user/googleLogin", params);
}

//商品详情
export function goods(params) {
  return request.posta("/api/index/goods", params);
}

//发送验证码
export function send(params) {
  return request.postc("/api/ems/send", params);
}

//会员登录
export function login(params) {
  return request.postc("/api/user/login", params);
}

//注销
export function logout(params) {
  return request.posta("/api/user/logout", params);
}

//注册会员
export function register(params) {
  return request.postc("/api/user/register", params);
}

//会员中心
export function userindex(params) {
  return request.geta("/api/user/index", params);
}

//修改会员个人信息
export function profile(params) {
  return request.posta("/api/user/profile", params);
}

//重置密码
export function resetpwd(params) {
  return request.posta("/api/user/resetpwd", params);
}

//收藏
export function collection(params) {
  return request.posta("/api/user/collection", params);
}

//收藏列表
export function myCollection(params) {
  return request.posta("/api/user/myCollection", params);
}

//下单
export function buy(params) {
  return request.posta("/api/user/buy", params);
}

//订单列表
export function orderList(params) {
  return request.posta("/api/user/orderList", params);
}

//开始服务
export function start(params) {
  return request.posta("/api/user/start", params);
}

//结束服务
export function end(params) {
  return request.posta("/api/user/end", params);
}

//取消订单
export function cancel(params) {
  return request.posta("/api/user/cancel", params);
}

//发布商品
export function setGoods(params) {
  return request.posta("/api/user/setGoods", params);
}

//发布商品
export function indexsell(params) {
  return request.get("/api/index/sell", params);
}

//语言列表
export function languaist(params) {
  return request.geta("/api/user/languageList", params);
}

//添加语言
export function languageSet(params) {
  return request.posta("/api/user/languageSet", params);
}

// 删除
export function languageDe(params) {
  return request.geta("/api/user/languageDel", params);
}

// 删除商品
export function delGoods(params) {
  return request.posta("/api/user/delGoods", params);
}

// 发布评论
export function comment(params) {
  return request.posta("/api/user/comment", params);
}

// 评论列表
export function commentList(params) {
  return request.posta("/api/index/commentList", params);
}

// 意见反馈
export function feedback(params) {
  return request.posta("/api/user/feedback", params);
}

// 去支付
export function pay(params) {
  return request.posta("/api/user/pay", params);
}

// 查询是否支付
export function onlinequery(params) {
  return request.postl("/api/user/onlinequery", params);
}

// 投诉
export function accuse(params) {
  return request.postl("/api/user/accuse", params);
}




// 余额明细
export function moneyLog(params) {
  return request.postl("/api/user/moneyLog", params);
}

// 积分明细
export function scoreLog(params) {
  return request.postl("/api/user/scoreLog", params);
}

// 提现申请
export function userCashApply(params) {
  return request.postl("/api/user/userCashApply", params);
}

// 提现记录
export function userCashLog(params) {
  return request.postl("/api/user/userCashLog", params);
}

// 取消提现 
export function cancelCash(params) {
  return request.postl("/api/user/cancelCash", params);
}

// 文章列表
export function articleList(params) {
  return request.postl("/api/index/articleList", params);
}

// 文章详情
export function article(params) {
  return request.postl("/api/index/article", params);
}

// 论坛分类列表
export function bbsTypeList(params) {
  return request.postl("/api/index/bbsTypeList", params);
}

// 论坛文章列表
export function bbsList(params) {
  return request.postl("/api/index/bbsList", params);
}

// 论坛文章详情
export function indexbbs(params) {
  return request.postl("/api/index/bbs", params);
}

// 论坛文章添加编辑
export function bbsSet(params) {
  return request.postl("/api/bbs/bbsSet", params);
}

// 论坛文章删除
export function bbsDel(params) {
  return request.postl("/api/bbs/bbsDel", params);
}

// 论坛文章点赞
export function bbsZan(params) {
  return request.postl("/api/bbs/bbsZan", params);
}

// 论坛文章收藏
export function bbsShou(params) {
  return request.postl("/api/bbs/bbsShou", params);
}

// 论坛文章收藏列表
export function bbsShouList(params) {
  return request.postl("/api/bbs/bbsShouList", params);
}

// 论坛文章添加评论
export function bbsCommentAdd(params) {
  return request.postl("/api/bbs/bbsCommentAdd", params);
}

// 论坛文章评论回复
export function bbsCommentReply(params) {
  return request.postl("/api/bbs/bbsCommentReply", params);
}

// 论坛文章评论列表
export function bbsCommentList(params) {
  return request.postl("/api/bbs/bbsCommentList", params);
}

// 论坛文章评论子评论列表
export function bbsCommentSonList(params) {
  return request.postl("/api/bbs/bbsCommentSonList", params);
}

// 充值
export function recharge(params) {
  return request.postl("/api/bbs/recharge", params);
}

// 充值记录
export function rechargeList(params) {
  return request.postl("/api/bbs/rechargeList", params);
}

// 任务列表
export function taskList(params) {
  return request.postl("/api/bbs/taskList", params);
}

// 任务详情
export function taskInfo(params) {
  return request.postl("/api/bbs/taskInfo", params);
}

// 任务发布
export function taskSet(params) {
  return request.postl("/api/bbs/taskSet", params);
}

// 任务取消
export function taskCancel(params) {
  return request.postl("/api/bbs/taskCancel", params);
}

// 任务报名
export function taskApply(params) {
  return request.postl("/api/bbs/taskApply", params);
}

// 任务确认
export function taskConfirm(params) {
  return request.postl("/api/bbs/taskConfirm", params);
}

// 任务结束
export function taskEnd(params) {
  return request.postl("/api/bbs/taskEnd", params);
}

// 任务验收
export function taskAccept(params) {
  return request.postl("/api/bbs/taskAccept", params);
}

// 任务投诉
export function taskAccuse(params) {
  return request.postl("/api/bbs/taskAccuse", params);
}

// 抽奖列表
export function activityList(params) {
  return request.postl("/api/bbs/activityList", params);
}

// 抽奖详情
export function activityInfo(params) {
  return request.postl("/api/bbs/activityInfo", params);
}

// 浏览记录
export function historyList(params) {
  return request.postl("/api/user/historyList", params);
}

// 申请退款
export function refund(params) {
  return request.postl("/api/user/refund", params);
}




export function activityLottery(params) {
  return request.postl("/api/bbs/activityLottery", params);
}


export function activityLog(params) {
  return request.postl("/api/bbs/activityLog", params);
}


export function indexarea(params) {
  return request.postl("/api/index/area", params);
}


export function indexsetAd(params) {
  return request.postl("/api/index/setAd", params);
}



export function level(params) {
  return request.postl("/api/index/level", params);
}

export function histindexist(params) {
  return request.postl("/api/bbs/historyList", params);
}


export function msgList(params) {
  return request.postl("/api/user/msgList", params);
}

export function readlistname(params) {
  return request.postl("/api/user/read", params);
}


export function getAd(params) {
  return request.postl("/api/index/getAd", params);
}



export function questlist(params) {
  return request.postl("/api/index/quest", params);
}

export function submitlist(params) {
  return request.postl("/api/user/submit", params);
}


export function authuser(params) {
  return request.postl("/api/user/authuser", params);
}


export function authsell(params) {
  return request.postl("/api/user/authsell", params);
}


export function articleTypeList(params) {
  return request.postl("/api/index/articleTypeList", params);
}

export function updateSendBirdToken(params) {
  return request.postl("/api/user/updateSendBirdToken", params);
}

export function useCoupon(params) {
  return request.postl("/api/user/useCoupon", params);
}